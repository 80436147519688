import styled from "styled-components"

import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { chargebeeAddonUrl } from "src/constants/hrefs"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderItems({
  lineItems,
  shippingLineItem,
}: {
  lineItems: TParadiseOrder["line_items"]
  shippingLineItem: TParadiseOrder["shipping_line_item"]
}) {
  const headers = [
    <div key={"item"}>Name</div>,
    <div key={"quantity"}>Quantity</div>,
    <div key={"unit-amount"}>Unit amount</div>,
    <div key={"amount"}>Amount</div>,
    <div key={"tax"}>Tax</div>,
    <div key={"discount"}>Discount</div>,
  ]

  const productLineItems = lineItems.map((i) => (
    <>
      <div>
        <MText variant="subtitleS">{i.description}</MText>
        <DescriptionMeta>
          <MText variant="bodyS" color="secondary">
            SKU: {i.sku}
          </MText>
          •
          {i.product_id && (
            <ExternalLink href={chargebeeAddonUrl(i.product_id)}>
              <MText variant="bodyS" color="secondary">
                {i.product_id}
              </MText>
            </ExternalLink>
          )}
        </DescriptionMeta>
      </div>
      <div>{i.quantity}</div>
      <div>{i.unit_price.formatted_amount}</div>
      <div>{i.price.formatted_amount}</div>
      <div>{i.tax.formatted_amount}</div>
      <div>{i.discount.formatted_amount}</div>
    </>
  ))

  const shippingLineItemRow = !!shippingLineItem ? (
    <>
      <div>
        <MText variant="subtitleS">{shippingLineItem.name}</MText>
      </div>
      <div>1</div>
      <div>{shippingLineItem.price.formatted_amount}</div>
      <div>{shippingLineItem.price.formatted_amount}</div>
      <div>{shippingLineItem.tax.formatted_amount}</div>
      <div>{shippingLineItem.discount.formatted_amount}</div>
    </>
  ) : null

  const rows = [...productLineItems, shippingLineItemRow].filter((i) => !!i)

  return (
    <div>
      <ParadiseTable
        header={headers}
        rows={rows}
        templateColumns="3fr repeat(5, 1fr)"
      />
    </div>
  )
}

const DescriptionMeta = styled.div`
  display: flex;
  gap: ${spacing.XS};
e`

import { useState } from "react"
import styled from "styled-components"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { HomeGroupAddExistingHomesList } from "src/components/HomeGroups/HomeGroupAddExistingHomesList"
import { Pager } from "src/components/Pager/Pager"
import {
  useDeleteHomeGroupHome,
  usePostHomeGroupHomes,
} from "src/data/homeGroups/queries/homeGroupHomeQueries"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"

export function HomeGroupBuildingAddExistingHomesDialog({
  open,
  onClose,
  homeGroupId,
  homeGroupName,
}: {
  open: boolean
  onClose: () => void
  homeGroupId: string
  homeGroupName: string
}) {
  const { t, langKeys } = useTranslate()

  const [offset, setOffset] = useState(0)

  const { org } = useOrganization()
  const [searchString, setSearchString] = useState("")

  const postHomeGroupHomes = usePostHomeGroupHomes()
  const deleteHomeGroupHome = useDeleteHomeGroupHome()

  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: { limit: HOME_LIST_LIMIT, offset, name: searchString },
  })

  function handleAdd(homeId: string) {
    postHomeGroupHomes.mutate({
      organizationId: org.id,
      homeGroupId,
      body: { home_id: homeId },
    })
  }

  function handleRemove(homeId: string) {
    deleteHomeGroupHome.mutate({
      orgId: org.id,
      homeGroupId,
      home_id: homeId,
    })
  }

  return (
    <MDialog
      open={open}
      onClose={onClose}
      title={t(langKeys.organizations_homegroup_add_homes_title, {
        name: `'${homeGroupName}'`,
      })}
    >
      <ContentWrapper>
        <SearchFilter
          initialValue={searchString}
          placeholder={t(langKeys.search)}
          onChange={setSearchString}
        />

        <HomeGroupAddExistingHomesList
          homeGroupId={homeGroupId}
          homes={fetchHomes.data?.homes}
          onAdd={handleAdd}
          onRemove={handleRemove}
          loadingHomeId={
            postHomeGroupHomes.isLoading
              ? postHomeGroupHomes.variables?.body.home_id
              : undefined
          }
          isLoadingHomes={fetchHomes.isLoading}
        />

        <Pager
          limit={HOME_LIST_LIMIT}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomes.data?.paging?.total_count || 0}
        />
      </ContentWrapper>
    </MDialog>
  )
}

const ContentWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
  max-height: 600px;
`

import { Dispatch, SetStateAction, useState } from "react"

import { AxiosError } from "axios"

import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { IPostHome } from "src/data/homes/types/homeQueryTypes"
import { ILocation, THome, THomeAddress } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchCreateHomeEstimate } from "src/data/organizations/queries/estimateQueries"
import { usePostHome } from "src/data/organizations/queries/homeQueries"
import { TFetchCreateHomeEstimateResponse } from "src/data/organizations/types/organizationTypes"
import { TSelectedPresetId } from "src/data/profileSettings/types/monitoringPresetTypes"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { ErrorService } from "src/utils/ErrorService"
import { getTimeZoneFromLoc } from "src/utils/l10n"

export type BlockerReason =
  | "needs-subcription"
  | "needs-reactivation"
  | "access-denied"

type TCreateHomeFnArgs = {
  name: string
  location: ILocation
  address: THomeAddress
  homeGroupId?: string
  selectedPresetId: TSelectedPresetId
  onSuccess?: (data: THome) => void
  onError?: (error: AxiosError) => void
}

export function useCreateHomeWizard({ quantity }: { quantity?: number }): {
  isLoadingCreateHomeEstimate: boolean
  hasCreateHomeEstimateError: boolean
  showBlocker: boolean
  blockerReason: BlockerReason | undefined
  hasPlanUnitPerHome: boolean
  checkedTerms: boolean
  onCheckTerms: () => void
  checkedTermsError: boolean
  createHomeEstimate?: TFetchCreateHomeEstimateResponse
  isCreatingHome: boolean
  isCreateHomeError: boolean
  createHome: (args: TCreateHomeFnArgs) => void
  setCreateHomeError: Dispatch<SetStateAction<boolean>>
  setCheckedTermsError: Dispatch<SetStateAction<boolean>>
} {
  const { org, orgId } = useOrganization()

  const [createHomeError, setCreateHomeError] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [checkedTermsError, setCheckedTermsError] = useState(false)

  const postHome = usePostHome()

  const createHomeAvailability = useFeatureAvailability({
    feature: "create_home",
    refresh: true,
  })

  const isHomegroupAdmin = useIsHomeGroupAdmin()
  const permissions = getAccessLogic({ role: org.user_role })
  const hasCreateHomePermission =
    permissions.hasAdminAccess || isHomegroupAdmin.value

  const hasPlanUnitPerHome = org.billing_model === "plan_unit_per_home"

  const fetchCreateHomeEstimate = useFetchCreateHomeEstimate({
    orgId,
    quantity: quantity || 0,
    options: {
      enabled: hasCreateHomePermission && hasPlanUnitPerHome && !!quantity,
    },
  })

  const needsSubscription =
    createHomeAvailability.ready && !createHomeAvailability.available

  const needsReactivation =
    !needsSubscription &&
    fetchCreateHomeEstimate.error?.response?.data?.error_key ===
      "invalid_subscription_status"

  const blockerReason = getBlockerReason({
    needsSubscription,
    needsReactivation,
    hasCreateHomePermission,
  })

  function onCheckTerms() {
    setCheckedTerms((prev) => !prev)
    setCheckedTermsError(false)
  }

  function createHome({
    name,
    location,
    address,
    homeGroupId,
    selectedPresetId,
    onSuccess,
    onError,
  }: TCreateHomeFnArgs) {
    if (!checkedTerms && hasPlanUnitPerHome) {
      setCheckedTermsError(true)
      return
    }

    if (!selectedPresetId.noise) {
      setCreateHomeError(true)
      return
    }

    const body: IPostHome = {
      name,
      location,
      address,
      homegroup_id: homeGroupId ?? undefined,
      timezone: getTimeZoneFromLoc(location.latitude, location.longitude),
      noise_profile_id: selectedPresetId.noise,
    }

    postHome.mutate(
      {
        orgId,
        body,
      },
      {
        onSuccess,
        onError: (error) => {
          setCreateHomeError(true)
          ErrorService.captureException(error)

          onError?.(error)
        },
      }
    )
  }

  return {
    isLoadingCreateHomeEstimate:
      hasPlanUnitPerHome && fetchCreateHomeEstimate.isLoading,
    hasCreateHomeEstimateError: fetchCreateHomeEstimate.isError,
    showBlocker: !!blockerReason,
    blockerReason: blockerReason,
    hasPlanUnitPerHome,
    checkedTerms,
    onCheckTerms,
    checkedTermsError,
    createHomeEstimate: fetchCreateHomeEstimate.data,
    isCreatingHome: postHome.isLoading,
    isCreateHomeError: createHomeError,
    createHome,
    setCreateHomeError,
    setCheckedTermsError,
  }
}

function getBlockerReason({
  needsSubscription,
  needsReactivation,
  hasCreateHomePermission,
}: {
  needsSubscription: boolean
  needsReactivation: boolean
  hasCreateHomePermission: boolean
}): BlockerReason | undefined {
  if (!hasCreateHomePermission) {
    return "access-denied"
  }

  if (needsSubscription) {
    return "needs-subcription"
  }
  if (needsReactivation) {
    return "needs-reactivation"
  }

  return undefined
}

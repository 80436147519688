import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MBadge, MBadgeProps } from "src/ui/MBadge/MBadge"

export function OrderStatusBadge({
  status,
  size = "x-small",
  textTransform = "capitalize",
}: {
  status: TParadiseOrder["status"]
  size?: MBadgeProps["size"]
  textTransform?: MBadgeProps["textTransform"]
}) {
  return (
    <MBadge size={size} color="info" textTransform={textTransform}>
      {status}
    </MBadge>
  )
}

import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import {
  ExpandableSection,
  TExpandableSection,
} from "src/ui/ExpandableSection/ExpandableSection"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type ExpandableFileRequirementsProps = {
  description?: string
  columns: {
    name: string
    description?: React.ReactNode
    required?: boolean
  }[]
} & Omit<TExpandableSection, "title" | "children">

export function ExpandableFileRequirements({
  description,
  columns,
  ...props
}: ExpandableFileRequirementsProps) {
  const { t, langKeys } = useTranslate()

  const headers = [
    <div key={"header"}>
      {t(langKeys.stay_importer_supported_columns_header)}
    </div>,
    <div key={"description"}>
      {t(langKeys.stay_importer_supported_columns_description)}
    </div>,
  ]

  const rows = columns.map((c) => (
    <>
      <div>
        <MText variant="subtitle">{c.name}</MText>
        <div>{c.required && <MBadge size="small">Required</MBadge>}</div>
      </div>
      <div>
        {c.description && <MText color="secondary">{c.description}</MText>}
      </div>
    </>
  ))

  return (
    <ExpandableSection
      variant="card"
      title={t(langKeys.home_group_import_homes_file_requirements_title)}
      {...props}
    >
      <Wrapper>
        {description && (
          <MText color="secondary" marginBottom={spacing.L}>
            {description}
          </MText>
        )}
        <MText variant="subtitleS" color="secondary" marginBottom={spacing.M}>
          {t(langKeys.file_requirements_supported_columns)}
        </MText>
        <GridTable
          header={headers}
          rows={rows}
          mobileRows={rows}
          minWidth={400}
          templateColumns="auto 1fr"
          disableRowHover
        />
      </Wrapper>
    </ExpandableSection>
  )
}

const Wrapper = styled.div`
  padding: ${spacing.M};
`

import { useUrlPager } from "src/components/Pager/useUrlPager"
import { IHomeFilterBody } from "src/data/homes/types/homeQueryTypes"
import {
  TSetSearchParamsProp,
  useSearchParams,
} from "src/router/useSearchParams"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import { TTableSort } from "src/ui/GridTable/useTableColumns/useTableColumns"
import { Nullable } from "src/utils/tsUtil"

type THomesFilter = Nullable<Pick<IHomeFilterBody, "name">>

export function useHomeGroupBuildingHomesFilter(): {
  filter: THomesFilter
  setFilter: TSetSearchParamsProp<THomesFilter>
  setOffset: (offset: number) => void
  limit: number
  offset: number
  sortValue: TTableSort | null
  setSortValue: (sortValue: TTableSort) => void
  filterApplied: boolean
} {
  const { limit, offset, setOffset } = useUrlPager()
  const { sortValue, setSortValue } = useSortParam()

  const { searchParams, setSearchParamsAll } = useSearchParams({
    keys: [
      {
        key: "name",
        type: "string",
      },
    ],
  })

  return {
    filter: searchParams,
    setFilter: (key, value) => {
      // Make sure we reset paging on filter
      setOffset(0)
      setSearchParamsAll({
        [key]: value,
      })
    },
    setOffset: (offset: number) => {
      setOffset(offset)
    },
    limit,
    offset,
    sortValue,
    setSortValue,
    filterApplied: Object.values(searchParams).some((value) => value !== null),
  }
}

export const colorScale = {
  hem: {
    100: "#ECEFF2",
    200: "#E5EAEE",
    300: "#DFE5EA",
    400: "#CBD5DD",
    500: "#BECBD4",
    600: "#ABB7BF",
    700: "#5f666a",
    800: "#4c5155",
  },
  casa: {
    100: "#fcf9fa",
    200: "#F9F4F4",
    300: "#F8F1F2",
    400: "#F5EBEC",
    500: "#F0E3E4",
    600: "#c0b6b6",
    700: "#908889",
    800: "#605b5b",
  },
  rumah: {
    100: "#F9FAFA",
    200: "#F0F3F2",
    300: "#E0E8E5",
    400: "#D1DCD8",
    500: "#C1D0CB",
    600: "#919C98",
    700: "#616866",
    800: "#303433",
  },
  gaff: {
    100: "#E9ECF2",
    200: "#C8CFE0",
    300: "#929FC0",
    400: "#5B6EA1",
    500: "#243E81",
    600: "#1B2F61",
    700: "#121F41",
    800: "#091020",
  },
  hejmo: {
    100: "#e8ebed",
    200: "#d1d6da",
    300: "#a3aeb5",
    400: "#758590",
    500: "#475d6b",
    600: "#1B2F61",
    700: "#193446",
    800: "#122431",
  },
  koti: {
    100: "#F7F7F7",
    200: "#E6E6E6",
    300: "#D6D6D6",
    400: "#A4A4A4",
    500: "#8b8b8b",
    600: "#707070",
    700: "#595959",
    800: "#1F1F1F",
  },
  good: {
    100: "#EDF5EE",
    200: "#D2E6D3",
    300: "#A6CEA8",
    400: "#79B57C",
    500: "#4C9C50",
    600: "#39753C",
    700: "#264E28",
    800: "#132714",
  },
  warning: {
    100: "#FEF8EC",
    200: "#FCEDCF",
    300: "#F9DBA0",
    400: "#F6C870",
    500: "#DA9422",
    600: "#B68930",
    700: "#7A4D20",
    800: "#3D2710",
  },
  error: {
    100: "#F9EBEC",
    200: "#F1CED0",
    300: "#E29DA1",
    400: "#D46B71",
    500: "#C53A42",
    600: "#942C32",
    700: "#631D21",
    800: "#310F11",
  },
  white: "#FFFFFF",
}

// Will replace the current colorLegacy object and palette in a later PR.
export const mColors = {
  primary: colorScale.gaff[500],
  primaryLight: colorScale.gaff[100],
  accentPrimary: colorScale.hem[500],
  accentPrimaryLight: colorScale.hem[100],
  accentSecondary: colorScale.casa[500],
  accentSecondaryLight: colorScale.casa[200],
  accentTertiary: colorScale.rumah[500],
  accentTertiaryLight: colorScale.rumah[200],
  neutral: colorScale.koti[100],
  neutralLight: colorScale.white,
  neutralDark: colorScale.koti[200],
  neutralDark2: colorScale.koti[300],
  neutralDark3: colorScale.koti[600],
  divider: colorScale.hem[300],
  dividerDark: colorScale.hem[400],
  textPrimary: colorScale.hejmo["700"],
  textInactive: colorScale.hejmo["500"],
  textSecondary: colorScale.koti["700"],
  textTertiary: colorScale.koti["600"],
  textContrast: colorScale.white,
  systemGood: colorScale.good["500"],
  systemGoodLight: colorScale.good["100"],
  systemGoodDark: colorScale.good["600"],
  systemWarning: colorScale.warning["500"],
  systemWarningLight: colorScale.warning["200"],
  systemWarningDark: colorScale.warning["700"],
  systemError: colorScale.error["500"],
  systemErrorLight: colorScale.error["100"],
  systemErrorDark: colorScale.error["600"],
  systemInfoLight: colorScale.gaff["100"],
  systemInfo: colorScale.gaff["500"],
  systemInfoDark: colorScale.hejmo["700"],
}

/** @deprecated use colorScale or colorsLegacy instead */
export const palette = {
  casa: "#F0E3E4", // pink
  dom: "#ACD0AF",
  koti: "#E1CD9A",
  heima: "#C7BDD0",
  gida: "#8BC1BB",
  hjem: "#E0997F",
  hem: "#BFCBD4", // grey-blue
  rumah: "#C1D0CB", // green
  hejmo: "#193446",
  hav: "#537AA1",
}

/** @deprecated use neutral color instead */
export const gray00 = "#1F1F1F"
/** @deprecated use neutral color instead */
export const gray10 = "#595959"
/** @deprecated use neutral color instead */
export const gray20 = "#707070"
/** @deprecated use neutral color instead */
export const gray30 = "#CECECE"
/** @deprecated use neutral color instead */
export const gray40 = "#E3E3E3"
/** @deprecated use neutral color instead */
export const gray50 = "#EFEFEF"
/** @deprecated use neutral color instead */
export const gray60 = "#F4F4F4"

/** @deprecated */
export const greyScale = {
  50: "#F7F7F9",
  100: "#EEEEF0",
  200: "#E2E2E4",
  300: "#D0D0D2",
  400: "#ABABAD",
  500: "#8A8A8C",
  600: "#636365",
  700: "#505052",
  800: "#323234",
  900: "#121214",
  gray00,
  gray10,
  gray20,
  gray30,
  gray40,
  gray50,
  gray60,
}

/** @deprecated use mColors instead */
export const primaryBackground = colorScale.white
/** @deprecated use mColors instead */
export const primaryTint = mColors.primary
/** @deprecated use mColors instead */
export const semanticInfo = mColors.systemInfoLight
/** @deprecated use mColors instead */
export const semanticGood = mColors.systemGood
/** @deprecated use mColors instead */
export const semanticEmergency = mColors.systemError
/** @deprecated use mColors instead */
export const semanticWarning = mColors.systemWarning
/** @deprecated use mColors instead */
export const recommendedGray = mColors.textSecondary
/** @deprecated use mColors instead */
export const minimumGray = mColors.textTertiary
/** @deprecated use mColors instead */
export const backgroundGray = mColors.neutral
/** @deprecated use mColors instead */
export const hoverRowBackground = mColors.neutral
/** @deprecated use mColors instead */
export const backgroundGrayV2 = mColors.neutral
/** @deprecated use mColors instead */
export const backgroundGrayV3 = mColors.neutral
/** @deprecated use mColors instead */
export const backgroundColor = mColors.neutralLight
/** @deprecated use mColors instead */
export const divider = mColors.divider
/** @deprecated use mColors instead */
export const brandText = mColors.textPrimary
/** @deprecated use mColors instead */
export const systemOkBackground = mColors.systemGoodLight
/** @deprecated use mColors instead */
export const systemOkForeground = mColors.systemGoodDark
/** @deprecated use mColors instead */
export const systemInfoBackground = mColors.systemInfoLight
/** @deprecated use mColors instead */
export const systemInfoForeground = mColors.systemInfoDark
/** @deprecated use mColors instead */
export const systemEmergencyBackground = mColors.systemErrorLight
/** @deprecated use mColors instead */
export const systemEmergencyForeground = mColors.systemErrorDark
/** @deprecated use mColors instead */
export const systemWarningBackground = mColors.systemWarningLight
/** @deprecated use mColors instead */
export const systemWarningForeground = mColors.systemWarningDark

/** @deprecated use mColors */
export const colorsLegacy = {
  backgroundGray,
  backgroundGrayV2,
  backgroundGrayV3,
  brandText,
  divider,
  minimumGray,
  primaryBackground,
  primaryTint,
  recommendedGray,
  semanticInfo,
  semanticGood,
  semanticEmergency,
  systemEmergencyBackground,
  systemEmergencyForeground,
  systemInfoBackground,
  systemInfoForeground,
  systemOkBackground,
  systemOkForeground,
} as const

/**
 * Converts a hex color to RGB components
 */
function hexToRGB(hex: string): [number, number, number] {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return [r, g, b]
}

/**
 * Converts RGB components to hex color
 */
function rgbToHex(r: number, g: number, b: number): string {
  return (
    "#" +
    [r, g, b].map((x) => Math.round(x).toString(16).padStart(2, "0")).join("")
  )
}

/**
 * Generates an array of colors interpolated between start and end colors
 * @param start - Starting hex color
 * @param end - Ending hex color
 * @param steps - Number of colors to generate (including start and end)
 * @returns Array of hex colors
 */
export function interpolateColors(
  start: string,
  end: string,
  steps: number
): string[] {
  const [r1, g1, b1] = hexToRGB(start)
  const [r2, g2, b2] = hexToRGB(end)

  const colors: string[] = []

  for (let i = 0; i < steps; i++) {
    const ratio = i / (steps - 1)

    const r = r1 + (r2 - r1) * ratio
    const g = g1 + (g2 - g1) * ratio
    const b = b1 + (b2 - b1) * ratio

    colors.push(rgbToHex(r, g, b))
  }

  return colors
}

import styled from "styled-components"

import { TPostImportHomeGroupHomesResponse } from "src/data/homeGroups/types/homeGroupTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { GridTable } from "src/ui/GridTable/GridTable"
import ImportantIcon from "src/ui/icons/important-outlined.svg"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroupBuildingPreviewStep({
  homeGroupName,
  uploadedHomes,
  totalHomes,
  importErrors,
}: {
  homeGroupName: string
  uploadedHomes: TPostImportHomeGroupHomesResponse["imported_homes"]
  totalHomes: number
  importErrors: { row: number; message: string }[]
}) {
  const { t, langKeys } = useTranslate()

  const headers = [
    <div key="unit-name">{t(langKeys.home_name)}</div>,
    <div key="unit-type">{t(langKeys.home_type)}</div>,
    <div key="floor">{t(langKeys.home_group_import_homes_floor)}</div>,
    <div key="room-number">
      {t(langKeys.home_group_import_homes_apt_number)}
    </div>,
    <div key="noise-monitoring-preset">
      {t(langKeys.home_group_import_homes_noise_preset)}
    </div>,
  ]

  const rows = uploadedHomes.map((home) => (
    <>
      <div>
        <MText variant="bodyS">{home.name}</MText>
      </div>
      <div>
        <MText variant="bodyS">{home.type}</MText>
      </div>
      <div>
        <MText variant="bodyS">{home.address?.floor_number}</MText>
      </div>
      <div>
        <MText variant="bodyS">{home.address?.apartment_number}</MText>
      </div>
      <div>
        <MText variant="bodyS">{home.noise_profile_id}</MText>
      </div>
    </>
  ))

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.home_group_import_homes_preview_homes_title)}
      </MText>
      <MText color="secondary" marginBottom={spacing.L}>
        {t(langKeys.home_group_import_homes_preview_homes_description, {
          homeGroup: homeGroupName,
        })}
      </MText>

      {importErrors.length > 0 && (
        <ErrorBanner
          type="warning"
          title={t(
            langKeys.home_group_import_homes_preview_homes_partial_error_title,
            {
              importedCount: uploadedHomes.length,
              totalCount: totalHomes,
            }
          )}
          icon={<ImportantIcon width={24} />}
          fullWidth
        >
          <MText variant="bodyS" color="unset">
            {t(
              langKeys.home_group_import_homes_preview_homes_partial_error_description
            )}
          </MText>
          <StyledUl>
            {importErrors.map((error) => (
              <li key={error.row}>
                <MText variant="bodyS" color="unset">
                  {t(langKeys.home_group_import_homes_error_line, {
                    line: error.row,
                    error: error.message,
                  })}
                </MText>
              </li>
            ))}
          </StyledUl>
        </ErrorBanner>
      )}

      <MCard border boxShadow={false}>
        <CardWrapper>
          <TopWrapper>
            <MText variant="subtitle">
              {t(langKeys.home_group_import_homes_preview_homes_details_title)}
            </MText>
            <MBadge>
              {uploadedHomes.length}{" "}
              {t(langKeys.home, {
                count: uploadedHomes.length,
              })}
            </MBadge>
          </TopWrapper>
          <TableWrapper>
            <GridTable
              header={headers}
              rows={rows}
              mobileRows={rows}
              minWidth={0}
              disableRowHover
              horizontalScroll
            />
          </TableWrapper>
        </CardWrapper>
      </MCard>
    </div>
  )
}

const ErrorBanner = styled(MBanner)`
  margin-bottom: ${spacing.L};
`

const StyledUl = styled.ul`
  margin: 0;
  padding-left: ${spacing.L};
`

const CardWrapper = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const TableWrapper = styled.div`
  white-space: nowrap;
  max-height: 400px;
  overflow-y: auto;
`

import styled from "styled-components"

import { spacing } from "src/ui/spacing"

export const ColumnSortWrapper = styled.div<{
  $justify?: "compact" | "grow"
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $justify }) =>
    $justify === "compact" ? "flex-start" : "space-between"};
  gap: ${spacing.S};

  &:hover {
    cursor: pointer;
  }
`

export const IconWrapper = styled.div<{ $up?: boolean }>`
  rotate: ${({ $up }) => ($up ? "180deg" : "0deg")};
`
export const UnsortedIconsWrapper = styled.div`
  display: grid;
  place-items: center;
  line-height: 0;
  opacity: 0.5;
  transition: opacity 0.2s;
  width: 10px;

  ${ColumnSortWrapper}:hover & {
    opacity: 1;
  }
`

import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { MBanner } from "src/ui/MBanner/MBanner"

export function ParadiseOrganizationAnalyticsTop10ByEvents({
  data,
}: {
  data: { home_name: string; number_of_events: number }[] | undefined
}) {
  const headers = [
    <div key={"home-name"}>Home name</div>,
    <div key={"count"}>Number of events</div>,
  ]

  if (!data || data.length === 0) {
    return (
      <MBanner type="info">
        No data for organization and time period found
      </MBanner>
    )
  }

  const rows = data.map(({ home_name, number_of_events }) => (
    <>
      <div>{home_name}</div>
      <div>{number_of_events}</div>
    </>
  ))

  return <ParadiseTable header={headers} rows={rows} />
}

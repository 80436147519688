import { useState } from "react"
import styled from "styled-components"

import {
  NoHomesInHomeGroup,
  NoHomesInHomeGroupWithDropdown,
} from "src/components/EmptyState/NoHomesInHomeGroup"
import { NoHomesOnFilter } from "src/components/EmptyState/NoHomesOnFilter"
import { SearchFilter } from "src/components/Filter/SearchFilter"
import { HomeGroupAddHomesDropdown } from "src/components/HomeGroups/HomegroupAddHomesDropdown"
import { HomeGroupBuildingAddExistingHomesDialog } from "src/components/HomeGroups/HomeGroupBuilding/HomeGroupBuildingAddExistingHomesDialog"
import { useHomeGroupBuildingHomesFilter } from "src/components/HomeGroups/HomeGroupBuilding/useHomeGroupBuildingHomesFilter"
import { Pager } from "src/components/Pager/Pager"
import {
  usePostAddExistingUnitsClicked,
  usePostAddUnitsToBuildingClicked,
  usePostCreateNewUnitsClicked,
} from "src/data/analytics/queries/homegroupAnalyticsQueries"
import { useDeleteHomeGroupHome } from "src/data/homeGroups/queries/homeGroupHomeQueries"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  parseFetchHomesSortField,
  useFetchHomes,
} from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroupBuildingHomes({
  homeGroupId,
  homeGroupName,
}: {
  homeGroupId: string
  homeGroupName: string
}) {
  // #region Analytics
  const postAddUnitsToBuildingClicked = usePostAddUnitsToBuildingClicked()
  const postCreateNewUnitsClicked = usePostCreateNewUnitsClicked()
  const postAddExistingUnitsClicked = usePostAddExistingUnitsClicked()
  // #endregion Analytics

  const { showBuildingHomeCreation } = useFlags()
  const { t, langKeys } = useTranslate()

  const { navigate } = useRouter()

  const { org } = useOrganization()

  const {
    filter,
    setFilter,
    filterApplied,
    offset,
    setOffset,
    limit,
    sortValue,
    setSortValue,
  } = useHomeGroupBuildingHomesFilter()

  const [showAddExistingHomeDialog, setShowAddExistingHomeDialog] =
    useState(false)

  const fetchHomeGroupHomes = useFetchHomes({
    orgId: org.id,
    filters: {
      homegroup_id: homeGroupId,
      name: filter.name || undefined,
      sort: parseFetchHomesSortField(sortValue?.id) ? sortValue?.id : undefined,
      sort_order: sortValue?.order,
      limit,
      offset,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const homes = fetchHomeGroupHomes.data?.homes || []
  const totalCount = fetchHomeGroupHomes.data?.paging.total_count

  const deleteHomeGroupHome = useDeleteHomeGroupHome()

  const isOrgAdmin = getAccessLogic({ role: org.user_role }).hasAdminAccess

  function handleDelete(home_id: string) {
    return deleteHomeGroupHome.mutate({
      orgId: org.id,
      homeGroupId,
      home_id,
    })
  }

  const tableColumns: TableColumn<THome>[] = [
    {
      value: "name",
      label: t(langKeys.name),
      render: (data) => (
        <InternalLink to={Routes.Home.location(data.home_id)}>
          {data.name}
        </InternalLink>
      ),
      enableSort: true,
    },
    {
      value: "home_type",
      label: t(langKeys.home_type),
      render: () => <MText color="secondary">-</MText>,
      columnWidth: "minmax(200px, 300px)",
    },
    {
      value: "floor",
      label: t(langKeys.home_address_floor),
      render: (data) => (
        <MText color="secondary">{data.address?.floor_number || "-"}</MText>
      ),
      columnWidth: "minmax(200px, 300px)",
    },
    {
      value: "apt_room_number",
      label: t(langKeys.home_address_apt_room_number),
      render: (data) => (
        <MText color="secondary">{data.address?.apartment_number || "-"}</MText>
      ),
      columnWidth: "minmax(200px, 300px)",
    },
    {
      value: "more",
      label: "",
      render: (data) => (
        <div>
          <ButtonBox>
            <MoreButton
              onDelete={() => {
                handleDelete(data.home_id)
              }}
              deleteLabel={t(langKeys.home_group_building_remove_home)}
            />
          </ButtonBox>
        </div>
      ),
      columnWidth: "1fr",
    },
  ]

  const { headerElements, rows, templateColumns } = useTableColumns({
    columns: tableColumns,
    data: homes,
    sort: sortValue,
    onSortChange: setSortValue,
  })

  if (fetchHomeGroupHomes.isError) {
    return (
      <MBanner type="error" title={t(langKeys.failed_something_went_wrong)} />
    )
  }

  if (!fetchHomeGroupHomes.isLoading && !filterApplied && homes.length === 0) {
    return showBuildingHomeCreation ? (
      <HomeGroupBuildingLayoutHomes
        homeGroupId={homeGroupId}
        homeGroupName={homeGroupName}
        showAddExistingHomeDialog={showAddExistingHomeDialog}
        setShowAddExistingHomeDialog={setShowAddExistingHomeDialog}
      >
        <NoHomesInHomeGroupWithDropdown
          type="building"
          onDropdownClick={() => {
            postAddUnitsToBuildingClicked.mutate()
          }}
          onCreateClick={() => {
            postCreateNewUnitsClicked.mutate()
            navigate(Routes.HomeGroupBuildingCreateHome.location(homeGroupId))
          }}
          onAddExistingClick={() => {
            postAddExistingUnitsClicked.mutate()
            setShowAddExistingHomeDialog(true)
          }}
        />
      </HomeGroupBuildingLayoutHomes>
    ) : (
      <NoHomesInHomeGroup
        type="building"
        onClick={() => navigate(Routes.HomeGroupAddHomes.location(homeGroupId))}
      />
    )
  }

  const showFilterEmpty = filterApplied && homes.length === 0

  return (
    <HomeGroupBuildingLayoutHomes
      homeGroupId={homeGroupId}
      homeGroupName={homeGroupName}
      showAddExistingHomeDialog={showAddExistingHomeDialog}
      setShowAddExistingHomeDialog={setShowAddExistingHomeDialog}
    >
      <HomeGroupHomesBox>
        <MText variant="heading2" textTransform="capitalize">
          {t(langKeys.unit, {
            count: homes.length,
          })}
        </MText>
        <Top>
          <TopRight>
            <SearchFilter
              placeholder={t(langKeys.search_for_home)}
              initialValue={filter.name || ""}
              onChange={(name) => setFilter("name", name)}
            />
          </TopRight>
          {isOrgAdmin && (
            <HomeGroupAddHomesDropdown
              buttonText={t(
                langKeys.home_group_buildings_homes_empty_state_action
              )}
              onCreateClick={() =>
                navigate(
                  Routes.HomeGroupBuildingCreateHome.location(homeGroupId)
                )
              }
              onAddExistingClick={() => setShowAddExistingHomeDialog(true)}
            />
          )}
        </Top>

        <TableWrapper $dimmed={fetchHomeGroupHomes.isPreviousData}>
          <GridTable
            header={headerElements}
            rows={rows || []}
            templateColumns={templateColumns}
            minWidth={0}
            mobileRows={rows || []}
            loading={fetchHomeGroupHomes.isLoading}
            horizontalScroll
          />
        </TableWrapper>

        {showFilterEmpty && <NoHomesOnFilter />}

        <PagerWrapper>
          <Pager
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={totalCount}
          />
        </PagerWrapper>
      </HomeGroupHomesBox>
    </HomeGroupBuildingLayoutHomes>
  )
}

function HomeGroupBuildingLayoutHomes({
  homeGroupId,
  homeGroupName,
  showAddExistingHomeDialog,
  setShowAddExistingHomeDialog,
  children,
}: {
  homeGroupId: string
  homeGroupName: string
  showAddExistingHomeDialog: boolean
  setShowAddExistingHomeDialog: (open: boolean) => void
  children: React.ReactNode
}) {
  return (
    <div>
      {children}
      <HomeGroupBuildingAddExistingHomesDialog
        homeGroupId={homeGroupId}
        homeGroupName={homeGroupName}
        open={showAddExistingHomeDialog}
        onClose={() => setShowAddExistingHomeDialog(false)}
      />
    </div>
  )
}

const TableWrapper = styled.div<{ $dimmed: boolean }>`
  opacity: ${({ $dimmed }) => ($dimmed ? 0.5 : 1)};
`

const HomeGroupHomesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.L};
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${spacing.M};
`

const TopRight = styled.div`
  display: flex;
  gap: ${spacing.M};
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

import { HomeGroupBuildingHomes } from "src/components/HomeGroups/HomeGroupBuilding/HomeGroupBuildingHomes"
import { HomeGroupLocationHomes } from "src/components/HomeGroups/HomeGroupLocation/HomeGroupLocationHomes"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { useFlags } from "src/hooks/useFlags"

export function HomeGroupHomes({
  id,
  type,
  homeGroupName,
}: {
  id: string
  type: THomeGroup["type"]
  homeGroupName: string
}) {
  const { showBuildingHomeCreation } = useFlags()

  if (type === "building" && showBuildingHomeCreation) {
    return (
      <HomeGroupBuildingHomes homeGroupName={homeGroupName} homeGroupId={id} />
    )
  }

  return <HomeGroupLocationHomes homeGroupId={id} />
}

import { useState } from "react"
import { Redirect } from "react-router-dom"

import { CreateHomeBlocker } from "src/components/FeatureBlockers/CreateHomeBlocker"
import { HomeGroupBuildingPreviewStep } from "src/components/HomeGroups/HomeGroupBuildingImportHomesWizard/HomeGroupBuildingPreviewStep"
import { HomeGroupBuildingSummaryStep } from "src/components/HomeGroups/HomeGroupBuildingImportHomesWizard/HomeGroupBuildingSummaryStep"
import {
  HomeGroupBuildingUploadFileStep,
  UPLOAD_FORM_ID,
} from "src/components/HomeGroups/HomeGroupBuildingImportHomesWizard/HomeGroupBuildingUploadFileStep"
import { SelectMonitoringPresetStep } from "src/components/Homes/CreateHomeWizard/SelectMonitoringPresetStep"
import { useCreateHomeWizard } from "src/components/Homes/CreateHomeWizard/useCreateHomeWizard"
import {
  usePostImportUnitConfirmed,
  usePostImportUnitFileUploaded,
  usePostImportUnitInitiated,
  usePostImportUnitReviewed,
} from "src/data/analytics/queries/homegroupAnalyticsQueries"
import {
  mockedImportResponseData,
  useFetchHomeGroup,
  usePostImportHomeGroupHomes,
} from "src/data/homeGroups/queries/homeGroupQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { TSelectedPresetId } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"
import { ErrorService } from "src/utils/ErrorService"

export function HomeGroupBuildingImportHomesWizard({
  homeGroupId,
}: {
  homeGroupId: string
}) {
  // #region Analytics
  const postImportUnitInitiated = usePostImportUnitInitiated()
  const postImportUnitFileUploaded = usePostImportUnitFileUploaded()
  const postImportUnitReviewed = usePostImportUnitReviewed()
  const postImportUnitConfirmed = usePostImportUnitConfirmed()
  // #endregion Analytics

  const { showBuildingHomeCreation } = useFlags()

  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()
  const { navigate, goBack } = useRouter()

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedPresetId, setSelectedPresetId] = useState<TSelectedPresetId>({
    icm: "",
    noise: "",
  })

  const [importedHomesCount, setImportedHomesCount] = useState(0)

  const [currentStep, setCurrentStep] = useState(0)
  // Mocked configuration
  const [flowType, setFlowType] =
    useState<keyof typeof mockedImportResponseData>("all_imported")

  const fetchHomeGroup = useFetchHomeGroup({
    homeGroupId,
    orgId,
  })

  const importHomeGroupHomes = usePostImportHomeGroupHomes({
    responseType: flowType,
  })

  const {
    isLoadingCreateHomeEstimate,
    hasCreateHomeEstimateError,
    createHomeEstimate,
    checkedTerms,
    checkedTermsError,
    onCheckTerms,
    showBlocker,
    blockerReason,
    hasPlanUnitPerHome,
    setCheckedTermsError,
  } = useCreateHomeWizard({
    quantity: importedHomesCount,
  })

  function handleUpload() {
    if (selectedFile) {
      postImportUnitFileUploaded.mutate()

      importHomeGroupHomes.mutate(
        {
          _orgId: orgId,
          _homeGroupId: homeGroupId,
          _selectedPresetId: selectedPresetId,
          _csv: selectedFile,
          _dryRun: true,
        },
        {
          onSuccess: (data) => {
            setImportedHomesCount(data.imported_homes.length)
            setCurrentStep((currStep) => currStep + 1)
          },
        }
      )
    } else {
      // This function should not run unless a file is selected
      ErrorService.captureMessage(
        "No file selected when trying to upload homes in building"
      )
    }
  }

  useEffectOnce(() => {
    postImportUnitInitiated.mutate()
  })

  // TODO: Remove in MON-1217
  if (!showBuildingHomeCreation) {
    return <Redirect to={Routes.HomeGroupDetails.location(homeGroupId)} />
  }

  const hasError = hasCreateHomeEstimateError || fetchHomeGroup.isError

  const stepper: IWizardStep[] = [
    {
      component: (
        <HomeGroupBuildingUploadFileStep
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          isUploading={importHomeGroupHomes.isLoading}
          onSubmit={() => {
            setCurrentStep((currStep) => currStep + 1)
          }}
          validationErrors={importHomeGroupHomes.error?.errors || []}
          hasInsufficientHomeTokens={
            importHomeGroupHomes.error?.error_key === "insufficient_home_tokens"
          }
          flowType={flowType}
          setFlowType={setFlowType}
          loading={fetchHomeGroup.isLoading}
          error={hasError}
        />
      ),
      onBack: () =>
        goBack({ defaultPath: Routes.HomeGroupDetails.location(homeGroupId) }),
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        form: UPLOAD_FORM_ID,
      },
      hideBackButton: true,
    },
    {
      component: (
        <SelectMonitoringPresetStep
          selectedPresetId={selectedPresetId}
          setSelectedPresetId={setSelectedPresetId}
          pluralizeText
        />
      ),
      onNext: handleUpload,
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        loading: importHomeGroupHomes.isLoading,
        disabled: !selectedPresetId.noise,
      },
    },
    {
      component: (
        <HomeGroupBuildingPreviewStep
          uploadedHomes={importHomeGroupHomes.data?.imported_homes || []}
          importErrors={importHomeGroupHomes.data?.errors || []}
          homeGroupName={fetchHomeGroup.data?.name || ""}
          totalHomes={importHomeGroupHomes.data?.total_homes || 0}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
      onNext: () => {
        postImportUnitReviewed.mutate()
        setCurrentStep((currStep) => currStep + 1)
      },
    },
    {
      component: (
        <HomeGroupBuildingSummaryStep
          createHomeEstimate={createHomeEstimate}
          isLoadingEstimate={isLoadingCreateHomeEstimate}
          hideBillingInformation={!hasPlanUnitPerHome}
          checkedTerms={checkedTerms}
          onCheckTerms={onCheckTerms}
          checkedTermsError={checkedTermsError}
          onReviewClick={() => setCurrentStep((curr) => curr - 1)}
          importHomesCount={importedHomesCount}
        />
      ),
      nextButtonLabel: t(
        langKeys.home_group_import_homes_floor_create_button_label
      ),
      nextButtonProps: {
        loading: importHomeGroupHomes.isLoading,
      },
      onBack: () => {
        setCurrentStep((curr) => curr - 1)
      },
      onNext: () => {
        if (hasPlanUnitPerHome && !checkedTerms) {
          setCheckedTermsError(true)
          return
        }

        if (selectedFile) {
          postImportUnitConfirmed.mutate()
          importHomeGroupHomes.mutate(
            {
              _orgId: orgId,
              _homeGroupId: homeGroupId,
              _selectedPresetId: selectedPresetId,
              _csv: selectedFile,
              _dryRun: false,
            },
            {
              onSuccess: () => {
                navigate(Routes.HomeGroupDetails.location(homeGroupId))
              },
            }
          )
        } else {
          ErrorService.captureException(
            "Homes importer file is not defined when creating homes"
          )
        }
      },
    },
  ]

  if (showBlocker) {
    return <CreateHomeBlocker blockerReason={blockerReason} />
  }

  return (
    <FullscreenWizard
      title={t(langKeys.create_home)}
      currentStep={currentStep}
      steps={stepper}
      onClose={() => navigate(Routes.HomeGroupDetails.location(homeGroupId))}
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}

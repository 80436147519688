import { paths } from "@minuthq/minut-api-types/schema"

import { THome } from "src/data/homes/types/homeTypes"
import {
  IBasePaginatedEndpoint,
  IPagingFilter,
} from "src/data/pagination/types/paginationTypes"
import { Maybe } from "src/utils/tsUtil"

export type THomeGroup =
  paths["/organizations/{organization_id}/homegroups/{homegroup_id}"]["get"]["responses"]["200"]["content"]["application/json"]

export interface IHomeGroupMember {
  member_id: string
  user_id: string
  joined_at: string // format: date-time
  role: HomeGroupRole
  name: string
  email: string
}

export interface IFetchHomeGroupMembers extends IBasePaginatedEndpoint {
  members: IHomeGroupMember[]
}

export enum HomeGroupRole {
  OWNER = "owner",
  ADMIN = "admin",
  MEMBER = "member",
  RESPONDER = "responder",
}

export enum HomeGroupRank {
  RESPONDER,
  MEMBER,
  ADMIN,
}

export interface IFetchHomeGroups extends IBasePaginatedEndpoint {
  homegroups: THomeGroup[]
}

export interface IFetchHomeGroupHomes extends IBasePaginatedEndpoint {
  homes: IHomeGroupHome[]
}

export type TPostHomeGroup =
  paths["/organizations/{organization_id}/homegroups"]["post"]["requestBody"]["content"]["application/json"]

export interface IPostHomeGroupHome {
  home_id: string
}

export interface IHomeGroupHome {
  id: string
  name: string
}

export type TPatchHomeGroup =
  paths["/organizations/{organization_id}/homegroups/{homegroup_id}"]["patch"]["requestBody"]["content"]["application/json"]

export type THomeGroupFilter =
  paths["/organizations/{organization_id}/homegroups"]["get"]["parameters"]["query"]

export interface IHomeGroupHomesFilter extends IPagingFilter {}

export type TMaybeHomeGroup = Maybe<THomeGroup>

export type TPostImportHomeGroupHomesResponse = {
  imported_homes: (THome & {
    type: "private" | "communal"
  })[]
  total_homes: number
  errors: {
    error_key: string
    message: string
    row: number
  }[]
}

export type TPostImportHomeGroupHomes400Response = {
  error_key: string
  error_message: string
  errors: {
    error_key: string
    message: string
    row: number
  }[]
}

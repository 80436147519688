import { format } from "date-fns"

import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { TMButtonProps } from "src/ui/Button/MButton"
import { getDatePickerTimeFormat } from "src/ui/DateRangePicker/datePickerLogic"
import RightArrowIcon from "src/ui/icons/right-arrow.svg"

export interface IDateFieldProps extends TMButtonProps {
  startDate: Date | null
  endDate: Date | null
  onClick: () => void
}

export function DateField({
  startDate,
  endDate,
  onClick,
  color = "default",
}: IDateFieldProps) {
  const { t, langKeys } = useTranslate()
  const handleClick = () => {
    onClick()
  }

  const formatType = getDatePickerTimeFormat(startDate, endDate)

  function DateFieldLabel() {
    return !!(startDate && endDate) ? (
      <>
        {startDate && format(startDate, formatType)}
        <RightArrowIcon width={20} color="inherit" />
        {endDate && format(endDate, formatType)}
      </>
    ) : (
      <>{t(langKeys.date)}</>
    )
  }

  return (
    <DropdownButton
      onClick={handleClick}
      color={color}
      displayValue={<DateFieldLabel />}
    />
  )
}

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { homeGroupKeys } from "src/data/homeGroups/queries/homeGroupQueryCache"
import {
  IFetchHomeGroups,
  THomeGroup,
  THomeGroupFilter,
  TPatchHomeGroup,
  TPostHomeGroup,
  TPostImportHomeGroupHomes400Response,
  TPostImportHomeGroupHomesResponse,
} from "src/data/homeGroups/types/homeGroupTypes"
import { TSelectedPresetId } from "src/data/profileSettings/types/monitoringPresetTypes"
import { ErrorService } from "src/utils/ErrorService"
import { sleep } from "src/utils/genericUtil"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"
import { enabledChecker } from "src/utils/reactQueryUtil"

export function useFetchHomeGroups({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter: THomeGroupFilter
  options?: UseQueryOptions<
    IFetchHomeGroups,
    AxiosError,
    IFetchHomeGroups,
    ReturnType<typeof homeGroupKeys.homeGroupsList>
  >
}) {
  async function fetchHomeGroups(): Promise<IFetchHomeGroups> {
    const response = await minutApiHttpClient.get<IFetchHomeGroups>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery(
    homeGroupKeys.homeGroupsList(orgId, filter),
    fetchHomeGroups,
    {
      ...options,
    }
  )
}

export function useFetchHomeGroup({
  orgId,
  homeGroupId,
  options,
}: {
  orgId: string
  homeGroupId: string
  options?: UseQueryOptions<
    THomeGroup,
    AxiosError,
    THomeGroup,
    ReturnType<typeof homeGroupKeys.homeGroup>
  >
}) {
  async function fetchHomeGroup(): Promise<THomeGroup> {
    const response = await minutApiHttpClient.get<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`
    )

    return response.data
  }

  return useQuery(homeGroupKeys.homeGroup(orgId, homeGroupId), fetchHomeGroup, {
    ...options,
    enabled: enabledChecker(options, homeGroupId),
  })
}

interface IPostHomeGroupMutation {
  orgId: string
  body: TPostHomeGroup
}

export function usePostHomeGroup() {
  const queryClient = useQueryClient()

  async function postHomeGroup({
    orgId,
    body,
  }: IPostHomeGroupMutation): Promise<THomeGroup> {
    const response = await minutApiHttpClient.post<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups`,
      body
    )

    return response.data
  }

  return useMutation<THomeGroup, AxiosError, IPostHomeGroupMutation>(
    postHomeGroup,
    {
      onSuccess: (vars) => {
        return queryClient.invalidateQueries(
          homeGroupKeys.homeGroups(vars.organization_id)
        )
      },
      onError: (error) => {
        ErrorService.captureException(error)
      },
    }
  )
}

interface IPatchHomeGroupMutation {
  orgId: string
  homeGroupId: string
  body: TPatchHomeGroup
}
export function usePatchHomeGroup() {
  const queryClient = useQueryClient()

  async function patchHomeGroup({
    orgId,
    homeGroupId,
    body,
  }: IPatchHomeGroupMutation): Promise<THomeGroup> {
    const response = await minutApiHttpClient.patch<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`,
      body
    )

    return response.data
  }

  return useMutation<THomeGroup, AxiosError, IPatchHomeGroupMutation>(
    patchHomeGroup,
    {
      onSuccess: (_, { orgId }) => {
        return queryClient.invalidateQueries(homeGroupKeys.homeGroups(orgId))
      },
    }
  )
}

interface IDeleteHomeGroupMutation {
  orgId: string
  homeGroupId: string
}
export function useDeleteHomeGroup() {
  const cache = useQueryClient()

  async function deleteHomeGroup({
    orgId,
    homeGroupId,
  }: IDeleteHomeGroupMutation): Promise<void> {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`
    )
    return response.data
  }

  return useMutation<
    void,
    AxiosError<{ error_key: "homegroup_not_empty" }>,
    IDeleteHomeGroupMutation
  >(deleteHomeGroup, {
    onSuccess(_, vars) {
      cache.invalidateQueries(homeGroupKeys.homeGroups(vars.orgId))
    },
  })
}

export function usePostImportHomeGroupHomes({
  responseType,
}: {
  responseType: keyof typeof mockedImportResponseData
}) {
  async function postImportHomeGroupHomes({
    _orgId,
    _homeGroupId,
    _selectedPresetId,
    _csv,
    _dryRun,
  }: {
    _orgId: string
    _homeGroupId: string
    _selectedPresetId: TSelectedPresetId
    _csv: File
    _dryRun?: boolean
  }) {
    await _csv.text()

    await sleep(1000)

    if (responseType === "insufficient_home_tokens") {
      throw mockedImportResponseData.insufficient_home_tokens
    }

    if (responseType === "validation_error") {
      throw mockedImportResponseData.validation_error
    }

    return mockedImportResponseData[responseType]
  }

  return useMutation<
    TPostImportHomeGroupHomesResponse,
    TPostImportHomeGroupHomes400Response,
    {
      _orgId: string
      _homeGroupId: string
      _selectedPresetId: TSelectedPresetId
      _csv: File
      _dryRun?: boolean
    }
  >({
    mutationFn: postImportHomeGroupHomes,
  })
}

export const mockedImportResponseData: {
  all_imported: TPostImportHomeGroupHomesResponse
  partial_imported: TPostImportHomeGroupHomesResponse
  validation_error: TPostImportHomeGroupHomes400Response
  insufficient_home_tokens: TPostImportHomeGroupHomes400Response
} = {
  all_imported: {
    imported_homes: [
      {
        home_id: "home1",
        name: "Home 1",
        country: ["SE"],
        address: {
          country: "SE",
          city: "Stockholm",
          post_code: "12345",
          street_name1: "123 Main Street",
          floor_number: "1",
          apartment_number: "1",
        },
        timezone: "Europe/Stockholm",
        organization_id: "org1",
        user_role: "admin",
        created_at: new Date().toISOString(),
        created_by: "user1",
        owner: {
          id: "user1",
          type: "user",
        },
        type: "private",
      },
      {
        home_id: "home2",
        name: "Home 2",
        country: ["NO"],
        address: {
          country: "NO",
          city: "Oslo",
          post_code: "0123",
          street_name1: "456 Park Avenue",
          floor_number: "2",
          apartment_number: "2A",
        },
        timezone: "Europe/Oslo",
        organization_id: "org1",
        user_role: "admin",
        created_at: new Date().toISOString(),
        created_by: "user2",
        owner: {
          id: "user2",
          type: "user",
        },
        type: "private",
      },
      {
        home_id: "home3",
        name: "Home 3",
        country: ["DK"],
        address: {
          country: "DK",
          city: "Copenhagen",
          post_code: "2100",
          street_name1: "789 Lake Road",
          floor_number: "3",
          apartment_number: "3B",
        },
        timezone: "Europe/Copenhagen",
        organization_id: "org1",
        user_role: "admin",
        created_at: new Date().toISOString(),
        created_by: "user3",
        owner: {
          id: "user3",
          type: "user",
        },
        type: "communal",
      },
      {
        home_id: "home4",
        name: "Home 4",
        country: ["FI"],
        address: {
          country: "FI",
          city: "Helsinki",
          post_code: "00100",
          street_name1: "321 Forest Street",
          floor_number: "4",
          apartment_number: "4C",
        },
        timezone: "Europe/Helsinki",
        organization_id: "org1",
        user_role: "admin",
        created_at: new Date().toISOString(),
        created_by: "user4",
        owner: {
          id: "user4",
          type: "user",
        },
        type: "private",
      },
    ],
    total_homes: 4,
    errors: [],
  },
  partial_imported: {
    imported_homes: [
      {
        home_id: "home1",
        name: "Home 1",
        country: ["SE"],
        address: {
          country: "SE",
          city: "Stockholm",
          post_code: "12345",
          street_name1: "123 Main Street",
          floor_number: "1",
          apartment_number: "1",
        },
        timezone: "Europe/Stockholm",
        organization_id: "org1",
        user_role: "admin",
        created_at: new Date().toISOString(),
        created_by: "user1",
        owner: {
          id: "user1",
          type: "user",
        },
        type: "private",
      },
    ],
    total_homes: 4,
    errors: [
      {
        error_key: "home_import_error",
        message: "Address could not be verfiied",
        row: 2,
      },
      {
        error_key: "home_import_error",
        message: "Owner not found",
        row: 3,
      },
      {
        error_key: "home_import_error",
        message: "Invalid home name",
        row: 4,
      },
    ],
  },
  validation_error: {
    error_key: "validation_error",
    error_message: "Invalid CSV format",
    errors: [
      {
        error_key: "home_import_error",
        message: "Home name is missing",
        row: 1,
      },
      {
        error_key: "home_import_error",
        message: "Address is missing",
        row: 2,
      },
      {
        error_key: "home_import_error",
        message: "Country is missing",
        row: 2,
      },
      {
        error_key: "home_import_error",
        message: "Timezone is missing",
        row: 3,
      },
    ],
  },
  insufficient_home_tokens: {
    error_key: "insufficient_home_tokens",
    error_message: "Insufficient home tokens",
    errors: [],
  },
} as const

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrganizationAnalyticsEvents } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsEvents"
import { ParadiseOrganizationAnalyticsStates } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsStates"
import { ParadiseOrganizationAnalyticsTop10ByMetric } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsTop10ByMetric"

export function ParadiseOrganizationAnalytics({ orgId }: { orgId: string }) {
  return (
    <div>
      <ParadiseDetailsSection title="States">
        <ParadiseOrganizationAnalyticsStates orgId={orgId} />
      </ParadiseDetailsSection>
      <ParadiseOrganizationAnalyticsEvents orgId={orgId} />

      <ParadiseDetailsSection title="Top 10 homes by metric">
        <ParadiseOrganizationAnalyticsTop10ByMetric />
      </ParadiseDetailsSection>
    </div>
  )
}

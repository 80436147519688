import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrderAmount } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderAmount"
import { ParadiseOrderItems } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderItems"
import { ParadiseOrderTrackingReferenceBadge } from "src/components/Paradise/ParadiseOrders/ParadiseOrderTrackingReferenceBadge"
import { chargebeeAddonUrl } from "src/constants/hrefs"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderFulfillment({ order }: { order: TParadiseOrder }) {
  return (
    <Grid>
      <ParadiseDetailsSection title="Fulfillment" />

      <WarehouseSection order={order} />

      <ParadiseOrderItems
        lineItems={order.line_items}
        shippingLineItem={order.shipping_line_item}
      />

      <ParadiseOrderAmount totalPrice={order.total_price} tax={order.tax} />
    </Grid>
  )
}

function WarehouseSection({ order }: { order: TParadiseOrder }) {
  return (
    <WarehouseGrid>
      <MText variant="bodyS" color="secondary">
        Assigned warehouse:
      </MText>
      <MText variant="bodyS" textTransform="capitalize">
        {order.assigned_warehouse}
      </MText>

      <MText variant="bodyS" color="secondary">
        Shipping method:
      </MText>

      {order.shipping_line_item?.shipping_rate_id && (
        <MText variant="bodyS">
          <ExternalLink
            showExternalIcon
            openInNewTab
            href={chargebeeAddonUrl(order.shipping_line_item?.shipping_rate_id)}
          >
            {order.shipping_line_item?.name}
          </ExternalLink>
        </MText>
      )}

      <MText variant="bodyS" color="secondary">
        Tracking reference:
      </MText>

      <ParadiseOrderTrackingReferenceBadge
        trackingReference={order.tracking_reference}
      />
    </WarehouseGrid>
  )
}

const Grid = styled.div`
  display: grid;
  gap: ${spacing.XS2};
`

const WarehouseGrid = styled.div`
  display: grid;
  column-gap: ${spacing.L};
  grid-template-columns: max-content 1fr;
`

import { components, paths } from "@minuthq/minut-api-types/schema"

export type THomeEventType = components["schemas"]["HomeEventType"]

export type TFetchHomeLogEventsQueryParams = Omit<
  paths["/homes/{home_id}/events"]["get"]["parameters"]["query"],
  "event_type"
> & {
  event_type?: THomeEventType[] // overriding to get better typing
}

export type TFetchHomeLogEventsResponse =
  paths["/homes/{home_id}/events"]["get"]["responses"]["200"]["content"]["application/json"]

export type THomeLogEvent = TFetchHomeLogEventsResponse["home_events"][number]

type TMetadataNoiseDetected = components["schemas"]["MetadataNoiseDetected"]
type TMetadataDeviceInfo = components["schemas"]["MetadataDeviceInfo"]

/** Type assertion that the event specific metadata is noise detected metadata */
export function isNoiseDetectedMetadata(
  eventMetadata: THomeLogEvent["metadata"]["event_specific_data"]
): eventMetadata is TMetadataNoiseDetected {
  if ((eventMetadata as { noise_count: number })?.noise_count !== undefined) {
    return true
  }
  return false
}

export function hasDeviceMetaData(
  eventSpecificData: THomeLogEvent["metadata"]["event_specific_data"]
): eventSpecificData is TMetadataDeviceInfo {
  const data = eventSpecificData as TMetadataDeviceInfo
  if (data?.device_id) {
    return true
  }
  return false
}

// NOTE: We might want to replace some of these groups with a more general group
// in the future
type THomeEventFilterGroupSpecific =
  | "alarm_recognition"
  | "button_pressed"
  | "crowd_detect"
  | "mold_risk"
  | "security_alarm"
  | "sensor_attached"
  | "sensor_installed"
  | "sensor_offline"
  | "sensor_online"
  | "sensor_removed"
  | "sensor_swap"
  | "smart_thermostat"
  | "water_leak"

type THomeEventFilterGroupGeneral =
  | "battery"
  | "call_assist"
  | "fire_and_co"
  | "guard_assist"
  | "humidity"
  | "noise"
  | "smoking_detection"
  | "temperature"

export type THomeEventFilterGroup =
  | THomeEventFilterGroupSpecific
  | THomeEventFilterGroupGeneral

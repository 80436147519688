import { ISettingContainerBaseProps } from "src/components/Settings/Setting/SettingContainer"
import { SettingStatic } from "src/components/Settings/Setting/SettingStatic"
import { spacing } from "src/ui/spacing"

export function ParadiseSettingStatic({
  title,
  value,
  ...props
}: ISettingContainerBaseProps & { value: React.ReactNode }) {
  const { titleProps, displayValueProps, ...rest } = props

  return (
    <SettingStatic
      title={title}
      value={value}
      titleProps={{
        variant: "nano",
        color: "secondary",
        ...titleProps,
      }}
      displayValueProps={{
        variant: "bodyS",
        color: "primary",
        textTransform: "capitalize",
        ...displayValueProps,
      }}
      gap={spacing.XS3}
      {...rest}
    />
  )
}
